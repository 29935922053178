<template>
  <div class="row border-bottom border-white">
    <div class="col-12 bg-secondary-light py-4 px-2 position-relative">
      <div class="text-center">
        <h6 class="mb-4">{{ $t('resubmit_interview_again') }}</h6>
        <router-link :to="{ name: 'resubmit-interview-appointment' }">
          <b-button class="mt-2 px-4" pill variant="primary">{{ $t('resubmit_interview_appointment') }}</b-button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style></style>
