<template>
  <div v-if="loanInformation" class="row d-flex justify-content-center">
    <div v-show="!isHide" class="col-12 bg-primary-light py-4 px-2 position-relative">
      <i class="ri-close-circle-line ri-xl text-white cancel-icon" @click="isHide = true"></i>
      <div class="text-center">
        <h6 v-if="currentPeroid == 0" class="text-white" v-html="$t('loan_approve')"></h6>
        <h6 class="text-white mt-4">
          {{
            $t('loan_approve_content', {
              amount: `${loanInformation.schedule[currentPeroid].pmt_amount} ${$t('bahts')}`,
              date: dateFormatter(loanInformation.schedule[currentPeroid].pmt_date),
            })
          }}
        </h6>
        <h6 class="text-white mt-4">{{ $t('payment_channel') }}</h6>
      </div>
    </div>
    <div class="col-12 pt-3">
      <h5>{{ loanApplication.contract_no_ref }}</h5>
    </div>
    <div class="col-6 small">{{ $t('repayment_date') }}</div>
    <div class="col-6 text-right">
      <h6>{{ loanInformation.schedule[currentPeroid].pmt_date | DateFormatter }}</h6>
    </div>
    <div class="col-6 small">{{ $t('repayment_amount') }}</div>
    <div class="col-6 text-right">
      <h6>{{ loanInformation.schedule[currentPeroid].pmt_amount | Currency }} {{ $t('bahts') }}</h6>
    </div>
    <div class="col-6 small">{{ $t('loan_size') }}</div>
    <div class="col-6 text-right">
      <h6>{{ loanInformation.principle_amount | Currency }} {{ $t('bahts') }}</h6>
    </div>
    <div class="col-6 small">{{ $t('loan_term') }}</div>
    <div class="col-6 text-right">
      <h6>{{ loanInformation.term_period }} {{ $t('months') }}</h6>
    </div>
    <div class="col-12 col-md-6 my-4 text-center">
      <router-link :to="{ name: 'payment', params: { type: 'branch' } }">
        <b-button class="w-100 mt-2" pill variant="outline-primary">{{ $t('repay_at_branch') }}</b-button>
      </router-link>
      <router-link :to="{ name: 'payment', params: { type: 'atm' } }">
        <b-button class="w-100 mt-2" pill variant="outline-primary">{{ $t('repay_at_atm') }}</b-button>
      </router-link>
      <router-link :to="{ name: 'payment', params: { type: 'mobile-banking' } }">
        <b-button class="w-100 mt-2" pill variant="outline-primary">{{ $t('repay_mobile_banking') }}</b-button>
      </router-link>
      <router-link :to="{ name: 'current-loan' }">
        <b-button class="w-100 mt-2" pill variant="primary">{{ $t('view_loan_detail') }}</b-button>
      </router-link>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
export default {
  // eslint-disable-next-line vue/require-prop-types
  props: ['loanApplication', 'loanInformation', 'currentPeroid'],
  data() {
    return {
      isHide: false,
    }
  },
  methods: {
    dateFormatter(dateStr) {
      var date = moment(dateStr).format('DD MMM YYYY')
      return date
    },
  },
}
</script>

<style></style>
