<template>
  <div v-if="loanApplication" class="row pt-3">
    <div class="col-12">
      <h5>{{ $t('pending_loan') }}</h5>
      <h6>{{ loanApplication.contract_no }}</h6>
    </div>
    <div class="col-6 small">{{ $t('loan_applied_date') }}</div>
    <div class="col-6 text-right">
      <h6>{{ loanApplication.created_at | DateFormatter }}</h6>
    </div>
    <div class="col-6 small">{{ $t('request_interview_date') }}</div>
    <div class="col-6 text-right">
      <h6>{{ loanApplication.appointment_date | DateFormatter }}</h6>
    </div>
    <div class="col-6 small">{{ $t('request_interview_time') }}</div>
    <div class="col-6 text-right">
      <h6>{{ loanApplication.appointment_time | TimeFormatter }}</h6>
    </div>
    <div class="col-6 small">{{ $t('loan_status') }}</div>
    <div class="col-6 text-right">
      <h6>{{ $t('pending') }}</h6>
    </div>

    <!-- <div class="col-12 my-4 text-center">
      <router-link :to="{ name: 'current-loan' }">
        <b-button class="mt-2 px-4" pill variant="primary">
          {{ $t("view_loan_detail") }}
        </b-button>
      </router-link>
    </div> -->
  </div>
</template>

<script>
export default {
  name: 'AccountPendingLoan',
  // eslint-disable-next-line vue/require-prop-types
  props: ['loanApplication'],
  methods: {
    changeLoanState() {
      this.$store.dispatch('loan/setLoanState', {
        loanState: 'pre_approve_branch',
      })
    },
  },
}
</script>

<style></style>
