<template>
  <div class="row border-bottom border-white">
    <div class="col-12 bg-secondary-light py-4 px-2 position-relative">
      <div class="text-center">
        <h6>{{ $t('your_loan_is_rejected') }}</h6>
        <h6 class="mt-4" v-html="message"></h6>
      </div>
    </div>
    <div class="col-12 pt-5 pb-3 text-center" v-html="$t('next_loan_approve', { date: addSixMonth(rejectDate) })"></div>
  </div>
</template>

<script>
import moment from 'moment'

export default {
  name: 'RejectLoan',
  // eslint-disable-next-line vue/require-prop-types
  props: ['message', 'rejectDate'],
  data() {
    return {
      isHide: false,
    }
  },
  methods: {
    addSixMonth(dateStr) {
      var currentDate = moment(dateStr)
      var futureMonth = moment(currentDate).add(6, 'M')
      var futureMonthEnd = moment(futureMonth).endOf('month')

      if (currentDate.date() != futureMonth.date() && futureMonth.isSame(futureMonthEnd.format('YYYY-MM-DD'))) {
        futureMonth = futureMonth.add(1, 'd')
      }

      return futureMonth.format('DD MMMM YYYY')
    },
  },
}
</script>

<style></style>
