<!--<template>
  <div class="row border-bottom border-white">
    <div class="col-12 bg-secondary-light py-4 px-2 position-relative">
      <div class="text-center">
        <h6>{{ $t('your_loan_is_postpone') }}</h6>
        <h6 class="mt-4" v-html="message"></h6>
      </div>
    </div>
  </div>
</template>-->

<template>
  <div v-if="application" class="row border-bottom border-white">
    <div
      v-if="application.to_appointment_branch && application.is_first_loan === 1"
      class="col-12 bg-primary-light py-4 px-2"
    >
      <div class="text-center">
        <h6 class="text-white">{{ $t('your_loan_postpone') }}</h6>

        <h6 class="text-white mt-4" v-html="$t('postpone_loan_content')"></h6>

        <router-link to="/pre-approve/make-appointment">
          <b-button class="mt-2 px-4 text-primary" pill variant="secondary">{{ $t('make_appointment') }}</b-button>
        </router-link>
      </div>
    </div>

    <div class="col-12 pt-3">
      <h5>
        {{
          application.to_appointment_branch && application.is_first_loan === 1
            ? $t('pre_approve_branch')
            : $t('postpone_loan')
        }}
      </h5>

      <h6>{{ application.contract_no }}</h6>
    </div>

    <div class="col-6 small">{{ $t('loan_size') }}</div>
    <div class="col-6 text-right">
      <h6>{{ application.approved_amount | Currency }} {{ $t('bahts') }}</h6>
    </div>
    <div class="col-6 small">{{ $t('loan_term') }}</div>
    <div class="col-6 text-right">
      <h6>{{ application.loan_term }} {{ $t('months') }}</h6>
    </div>
    <div v-if="application.appointment_branch_date" class="col-6 small">{{ $t('branch_appointment_date') }}</div>
    <div v-if="application.appointment_branch_date" class="col-6 text-right">
      <h6>{{ application.appointment_branch_date | DateFormatter }}</h6>
    </div>
    <div v-if="application.appointment_branch_time" class="col-6 small">{{ $t('branch_appointment_time') }}</div>
    <div v-if="application.appointment_branch_time" class="col-6 text-right">
      <h6>{{ application.appointment_branch_time }}</h6>
    </div>
    <div v-if="application.status == 'pre-approved'" class="col-12 text-center mt-4">
      <router-link :to="{ name: 'pre-approve-make-appointment' }">
        <b-button class="mt-2 px-4" pill variant="primary">{{ $t('resubmit_interview_appointment') }}</b-button>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AccountPostponeLoan',
  // eslint-disable-next-line vue/require-prop-types
  props: ['application'],
  data() {
    return {
      isHide: false,
    }
  },
}
</script>

<style></style>
