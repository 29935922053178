<template>
  <div class="row border-bottom border-white">
    <div class="col-12 bg-secondary-light py-4 px-2 position-relative">
      <div class="text-center">
        <h6>{{ $t('information_need_update') }}</h6>
        <h6 class="mt-4">{{ $t('information_need_update_content') }}</h6>
        <router-link :to="{ name: 'update-information' }">
          <b-button class="mt-2 px-4" pill variant="primary">{{ $t('reupload_request') }}</b-button>
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
export default {}
</script>

<style></style>
