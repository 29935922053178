<template>
  <div class="container">
    <payment-late v-if="totalLateDate" :total="totalLateDate" :amount="paymentScheduleList[currentPeroid].pmt_amount" />

    <div v-if="!loanApplication && !isBusy">
      <div class="text-center mt-5">There is no loan</div>
    </div>
    <div v-if="loanApplication">
      <!-- Document Request -->
      <update-information v-if="documents_request" />
      <!-- Document Request -->

      <!-- ResubmitInterviewAppointment -->
      <resubmit-interview-appointment v-if="appointmentResubmit" />
      <!-- ResubmitInterviewAppointment -->

      <!-- Pending -->
      <div v-if="status == 'pending' || status == 'complete-review'">
        <pending-loan :loan-application="loanApplication" />
      </div>
      <!-- Pending -->

      <!-- Pre Approve -->
      <div
        v-else-if="
          status == 'pre-approved' ||
          status == 'thlo-approved' ||
          status == 'approved' ||
          status == 'agree' ||
          status == 'ready-to-disburse' ||
          status == 'disburse-approved'
        "
      >
        <pre-approve :application="loanApplication" />
      </div>
      <!-- Pre Approve -->

      <!-- Approve -->
      <div v-else-if="status == 'disbursed'">
        <approve
          :loan-application="loanApplication"
          :loan-information="loanInformation"
          :current-peroid="currentPeroid"
        />
      </div>
      <!-- Approve -->

      <!-- Reject -->
      <div v-else-if="status == 'reject'">
        <reject-loan :message="rejectionMessage" :reject-date="rejectDate" />
      </div>
      <!-- Reject -->

      <!-- Postpone -->
      <div v-else-if="status == 'postpone'">
        <!-- <postpone-loan :message="rejectionMessage" /> -->
        <postpone-loan :application="loanApplication" />
      </div>
      <!-- Postpone -->
    </div>

    <loading :is-busy="isBusy"></loading>
  </div>
</template>

<script>
import PendingLoan from './loanstate/PendingLoan.vue'
import UpdateInformation from './loanstate/UpdateInformation.vue'
import ResubmitInterviewAppointment from './loanstate/ResubmitInterviewAppointment.vue'
import PreApprove from './loanstate/PreApprove.vue'
import Approve from './loanstate/Approve.vue'
import RejectLoan from './loanstate/RejectLoan.vue'
import PostponeLoan from './loanstate/PostponeLoan.vue'
import PaymentLate from '@/components/PaymentLate.vue'

import LoanService from '@/api/service/LoanService'
import RejectionService from '@/api/service/RejectionService'
import NotificationService from '@/api/service/NotificationService'
import AccountService from '@/api/service/AccountService'

import Loading from '@/components/Loading.vue'
import moment from 'moment'
export default {
  components: {
    PendingLoan,
    UpdateInformation,
    ResubmitInterviewAppointment,
    PreApprove,
    Approve,
    RejectLoan,
    PostponeLoan,
    PaymentLate,
    Loading,
  },
  data() {
    return {
      isBusy: false,
      status: '',
      loanApplication: null,
      loanInformation: null,
      paymentScheduleList: [],
      rejectionMessage: '',
      documents_request: null,
      appointmentResubmit: false,
      rejectDate: null,
    }
  },

  computed: {
    currentPaymentDate() {
      var pmt_date = null
      for (var i = 0; i < this.paymentScheduleList.length; i++) {
        if (this.paymentScheduleList[i].status === 'unpaid') {
          pmt_date = this.paymentScheduleList[i].pmt_date
          break
        }
      }
      return pmt_date
    },
    currentPeroid() {
      var period = this.paymentScheduleList.length
      for (var i = 0; i < this.paymentScheduleList.length; i++) {
        if (this.paymentScheduleList[i].status === 'unpaid') {
          period = i
          break
        }
      }
      return period
    },
    totalLateDate() {
      if (this.currentPaymentDate) {
        const paymentDate = moment(this.currentPaymentDate, 'YYYY-MM-DD')
        const current = moment()

        const diff = current.diff(paymentDate, 'days')
        if (diff > 0) {
          return diff
        }
        return null
      }
      return null
    },
    locale() {
      return this.$i18n.locale
    },
  },
  watch: {
    locale() {
      this.getLoanApplication()
      this.changeLanguage()
    },
  },
  created() {
    this.getLoanApplication()
    this.getNotificationCount()
  },
  methods: {
    async getLoanApplication() {
      try {
        this.isBusy = true
        const res = await LoanService.loanApplication()
        this.isBusy = false
        if (res.status == 200) {
          const loanResponse = res.data.data
          // 'pending' => 'pending',
          // 'complete_review' => 'complete-review',
          // 'thlo_approved' => 'thlo-approved',
          // 'pre_approved' => 'pre-approved',
          // 'approved' => 'approved',
          // 'agree' => 'agree',
          // 'ready_to_disburse' => 'ready-to-disburse',
          // 'disburse_approved' => 'disburse-approved',
          // 'disbursed' => 'disbursed',
          // 'complete' => 'complete',
          // 'postpone' => 'postpone',
          // 'reject' => 'reject',
          this.loanApplication = loanResponse
          if (this.loanApplication) {
            this.status = loanResponse.status
            this.documents_request = loanResponse.documents_request
            this.appointmentResubmit = loanResponse.appointment_resubmit
            if (this.status == 'disbursed') {
              this.getLoanInformation()
            }
            // if (this.status == 'postpone') {
            //   const rejectCode = loanResponse.reject_code
            //   this.getRejectMessage(rejectCode)
            // }
            if (this.status == 'reject') {
              const rejectCode = loanResponse.reject_code
              this.rejectDate = loanResponse.reject_date
              this.getRejectMessage(rejectCode)
            }
          }
        }
      } catch (err) {
        this.isBusy = false
        this.showAlert(err)
      }
    },
    async getLoanInformation() {
      try {
        this.isBusy = true
        const res = await LoanService.loanInformation()
        this.isBusy = false
        if (res.status == 200) {
          const loanResponse = res.data.data
          this.loanInformation = loanResponse
          this.paymentScheduleList = loanResponse.schedule
        }
      } catch (err) {
        this.isBusy = false
        this.showAlert(err)
      }
    },
    async getRejectMessage(rejectCode) {
      try {
        this.isBusy = true
        const res = await RejectionService.getRejectionStatus({
          reject_code: rejectCode,
        })
        this.isBusy = false
        const rejectionResponse = res.data.data
        this.rejectionMessage = rejectionResponse.message
      } catch (err) {
        this.isBusy = false
        this.showAlert(err)
      }
    },
    async getNotificationCount() {
      try {
        const { data } = await NotificationService.notificationCount()
        this.$store.dispatch('notification/setCount', {
          count: data.data,
        })
      } catch (err) {
        console.log(err)
      }
    },
    async changeLanguage() {
      try {
        await AccountService.setLanguage()
      } catch (err) {
        console.log(err)
      }
    },
  },
}
</script>

<style></style>
