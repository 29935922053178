var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"container"},[(_vm.totalLateDate)?_c('payment-late',{attrs:{"total":_vm.totalLateDate,"amount":_vm.paymentScheduleList[_vm.currentPeroid].pmt_amount}}):_vm._e(),(!_vm.loanApplication && !_vm.isBusy)?_c('div',[_c('div',{staticClass:"text-center mt-5"},[_vm._v("There is no loan")])]):_vm._e(),(_vm.loanApplication)?_c('div',[(_vm.documents_request)?_c('update-information'):_vm._e(),(_vm.appointmentResubmit)?_c('resubmit-interview-appointment'):_vm._e(),(_vm.status == 'pending' || _vm.status == 'complete-review')?_c('div',[_c('pending-loan',{attrs:{"loan-application":_vm.loanApplication}})],1):(
        _vm.status == 'pre-approved' ||
        _vm.status == 'thlo-approved' ||
        _vm.status == 'approved' ||
        _vm.status == 'agree' ||
        _vm.status == 'ready-to-disburse' ||
        _vm.status == 'disburse-approved'
      )?_c('div',[_c('pre-approve',{attrs:{"application":_vm.loanApplication}})],1):(_vm.status == 'disbursed')?_c('div',[_c('approve',{attrs:{"loan-application":_vm.loanApplication,"loan-information":_vm.loanInformation,"current-peroid":_vm.currentPeroid}})],1):(_vm.status == 'reject')?_c('div',[_c('reject-loan',{attrs:{"message":_vm.rejectionMessage,"reject-date":_vm.rejectDate}})],1):(_vm.status == 'postpone')?_c('div',[_c('postpone-loan',{attrs:{"application":_vm.loanApplication}})],1):_vm._e()],1):_vm._e(),_c('loading',{attrs:{"is-busy":_vm.isBusy}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }